import {
  AssetDetailsData,
  AssetPropertiesDTO,
} from "../../../utils/entities/assets"
import { AssetSchema } from "../../../pages/v3/Assets/utils/schema"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { assetsApi } from "../AssetsApi"

const pause = (duration: number | undefined) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

const assetDetailsFromSchema = ({
  properties,
  description,
  sensorId,
  name,
  type,
}: AssetSchema): Omit<AssetDetailsData, "id"> => {
  const propertiesObj: AssetPropertiesDTO = {}

  properties.forEach(({ property, value }) => {
    propertiesObj[property] = value
  })

  return {
    isRoot: false,
    createdAt: Date.now(),
    description,
    name,
    historicalData: [],
    sensor: sensorId
      ? {
          name: sensorId,
          serialNumber: sensorId,
          services: [],
        }
      : undefined,
    properties: propertiesObj,
    type: Number(type),
  }
}

export const assetsDetailsApi = createApi({
  reducerPath: "assetsDetails",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3005",
    fetchFn: async (...args) => {
      //"This section ('await pause') of code is for testing purposes only
      //and will be removed before production deployment."
      await pause(1000)
      return fetch(...args)
    },
  }),
  tagTypes: ["AssetsDetails"],
  endpoints: (build) => ({
    fetchAssetDetails: build.query<AssetDetailsData, string>({
      query: (assetId) => {
        return {
          url: `/assets-details/${assetId}`,
          method: "GET",
        }
      },
      providesTags: (response) => [{ type: "AssetsDetails", id: response?.id }],
    }),
    updateAssetDetails: build.mutation<
      AssetDetailsData,
      { id: string; body: AssetSchema }
    >({
      query: ({ id, body }) => {
        return {
          method: "PUT",
          url: `/assets-details/${id}`,
          body: assetDetailsFromSchema(body),
        }
      },
      invalidatesTags: (response) => [
        { type: "AssetsDetails", id: response?.id },
      ],
    }),
    updateSensor: build.mutation<
      AssetDetailsData,
      { assetId: string; sensorId: string }
    >({
      query: ({ assetId, sensorId }) => {
        return {
          method: "PATCH",
          url: `/assets-details/${assetId}`,
          body: {
            sensor: {
              name: sensorId,
              serialNumber: sensorId,
              services: [],
            },
          },
        }
      },
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled

          dispatch(
            assetsDetailsApi.util.updateQueryData(
              "fetchAssetDetails",
              request.assetId,
              (draft) => {
                draft.sensor = result.data.sensor
              }
            )
          )
        } catch {
          //
        }
      },
    }),
    removeSensor: build.mutation<AssetDetailsData, string>({
      query: (assetId) => {
        return {
          method: "PATCH",
          url: `/assets-details/${assetId}`,
          body: {
            sensor: {},
          },
        }
      },
      async onQueryStarted(assetId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            assetsDetailsApi.util.updateQueryData(
              "fetchAssetDetails",
              assetId,
              (draft) => {
                draft.sensor = undefined
              }
            )
          )
        } catch {
          //
        }
      },
    }),
    addAssetDeitails: build.mutation<
      AssetDetailsData,
      {
        parent: string
        id: string
        data: AssetSchema
      }
    >({
      query: ({
        parent,
        id,
        data: { description, name, properties, type, sensorId },
      }) => {
        const propertiesObj: AssetPropertiesDTO = {}

        properties.forEach(({ property, value }) => {
          propertiesObj[property] = value
        })

        return {
          method: "POST",
          url: "/assets-details",
          body: {
            id: id.toString(),
            isRoot: false,
            createdAt: Date.now(),
            description,
            name,
            historicalData: [],
            sensor: sensorId
              ? {
                  name: sensorId,
                  id: sensorId,
                  serialNumber: sensorId,
                  services: [],
                }
              : undefined,
            properties: propertiesObj,
            type: Number(type),
          } as AssetDetailsData,
        }
      },
      async onQueryStarted({ parent, data }, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled

          dispatch(
            assetsApi.util.updateQueryData(
              "fetchAssets",
              undefined,
              (draft) => {
                const children = draft[parent].children

                draft[parent].isFolder = true

                if (children === undefined) {
                  draft[parent].children = []

                  const children = draft[parent].children
                  if (children) children.push(response.data.id)
                } else {
                  children.push(response.data.id)
                }

                draft[response.data.id] = {
                  index: response.data.id,
                  data: {
                    id: response.data.id,
                    name: data.name,
                    type: Number(data.type),
                  },
                  children: [],
                  isFolder: false,
                  canMove: false,
                  canRename: false,
                }
              }
            )
          )
        } catch {
          //
        }
      },
      invalidatesTags: (response) => [
        { type: "AssetsDetails", id: response?.id },
      ],
    }),

    removeAssetDetails: build.mutation<void, string>({
      query: (assetId) => {
        return {
          method: "DELETE",
          url: `/assets-details/${assetId}`,
        }
      },
      async onQueryStarted(assetId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            assetsApi.util.updateQueryData(
              "fetchAssets",
              undefined,
              (draft) => {
                delete draft[assetId]

                const keys = Object.keys(draft)

                for (let i = 0; i < keys.length; i++) {
                  const key = keys[i]

                  const children = draft[key].children

                  if (!children) return

                  if (children.includes(assetId)) {
                    if (children.length === 0) {
                      draft[key].isFolder = false
                      draft[key].children = undefined
                      return
                    }

                    draft[key].children = children.filter(
                      (item) => item !== assetId
                    )
                  }
                }
              }
            )
          )
        } catch {
          //
        }
      },
    }),
  }),
})

export const {
  useAddAssetDeitailsMutation,
  useFetchAssetDetailsQuery,
  useUpdateAssetDetailsMutation,
  useUpdateSensorMutation,
  useRemoveSensorMutation,
  useRemoveAssetDetailsMutation,
} = assetsDetailsApi
