import { useNavigate, useParams } from "react-router-dom"
import {
  useAddAssetDeitailsMutation,
  useAddAssetItemMutation,
} from "../../../../../store/store"
import {
  useFetchAssetTreeQuery,
  useUpdateAssetTreeMutation,
} from "../../../../../store/api/AssetsApi"
import { useMemo } from "react"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useCreateAsset = () => {
  const { assets } = useAppTranslate()
  const navigate = useNavigate()
  const { parentId } = useParams()

  const [addAsset, mutation] = useAddAssetDeitailsMutation()

  // TODO: Remove when integrated with the backend api
  const [addAssetTree, addTreeMutation] = useAddAssetItemMutation()
  const [updateAssetTree, updateTreeMutation] = useUpdateAssetTreeMutation()
  const { data: parent, refetch } = useFetchAssetTreeQuery(parentId!)

  const isLoading = useMemo(
    () =>
      mutation.isLoading ||
      addTreeMutation.isLoading ||
      updateTreeMutation.isLoading,
    [
      mutation.isLoading,
      addTreeMutation.isLoading,
      updateTreeMutation.isLoading,
    ]
  )

  const handleCancel = () => {
    navigate("/assets")
  }

  const handleCreate = async (data: AssetSchema) => {
    if (!parentId) return

    try {
      const id = (Math.round(Math.random() * 100) + 10).toString()

      await addAssetTree({
        id: id,
        name: data.name,
        type: Number(data.type),
      })

      if (!parent) return

      const result = await refetch().unwrap()

      await updateAssetTree({
        id: parentId,
        children: [...result.children, id],
      })

      await addAsset({
        id,
        parent: parentId,
        data,
      }).unwrap()

      toast.success(assets.form.toastSucessCreate)

      navigate("/assets")
    } catch (error) {
      //
    }
  }

  return {
    handleCancel,
    handleCreate,
    isLoading,
  }
}
