import { configureStore } from "@reduxjs/toolkit"
import modalReducer from "./features/modal/modal.slice"
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist"

import { persistedReducer } from "./persister"
import { api } from "./api/GatewaysWsApi"
import { gatewaysApi } from "./api/GatewaysApi"
import { assetsApi } from "./api/AssetsApi"
import { assetSensorsApi } from "./api/assets/sensors"
import { assetsDetailsApi } from "./api/assets/assets-details"

import { setupListeners } from "@reduxjs/toolkit/query"
import socketMiddleware from "./middlewares/socketMiddleware"

export const store = configureStore({
  reducer: {
    persistedReducer,
    modalReducer,
    [api.reducerPath]: api.reducer,
    [gatewaysApi.reducerPath]: gatewaysApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [assetSensorsApi.reducerPath]: assetSensorsApi.reducer,
    [assetsDetailsApi.reducerPath]: assetsDetailsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    })
      .concat(api.middleware)
      .concat(gatewaysApi.middleware)
      .concat(assetsApi.middleware)
      .concat(assetSensorsApi.middleware)
      .concat(assetsDetailsApi.middleware)
      .concat(socketMiddleware),
})

export const persisted = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const { useGetMessagesQuery } = api
export { useFetchGatewaysQuery } from "./api/GatewaysApi"
export {
  useFetchAssetsQuery,
  useFetchAssetTreeQuery,
  useRemoveAssetTreeMutation,
  useAddAssetItemMutation,
} from "./api/AssetsApi"
export { useFetchSensorsQuery } from "./api/assets/sensors"
export {
  useAddAssetDeitailsMutation,
  useFetchAssetDetailsQuery,
  useUpdateAssetDetailsMutation,
  useRemoveAssetDetailsMutation,
} from "./api/assets/assets-details"
