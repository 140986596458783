import * as z from "zod"
import { AssetDetailsData } from "../../../../utils/entities/assets"

const MAX_FILE_SIZE = 5000000 // Max file size is 5MB

const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
]

export const enum SCHEMA_ERRORS {
  INVALID_IMAGE_TYPE = "INVALID_IMAGE_TYPE",
  INVALID_FILE_SIZE = "INVALID_FILE_SIZE",
  REQUIRED = "REQUIRED",
}

export const assetSchema = z.object({
  name: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
  type: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
  description: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
  image: z
    .any()
    .refine((file: FileList) => {
      if (!file) return true
      if (file.length === 0) return true

      return file[0].size <= MAX_FILE_SIZE
    }, SCHEMA_ERRORS.INVALID_FILE_SIZE)
    .refine((file) => {
      if (!file) return true
      if (file.length === 0) return true

      return ACCEPTED_IMAGE_TYPES.includes(file[0].type)
    }, SCHEMA_ERRORS.INVALID_IMAGE_TYPE),
  properties: z.array(
    z.object<{ property: z.ZodString; value: z.ZodString }>({
      property: z.string(),
      value: z.string(),
    })
  ),
  sensorId: z.string(),
})

export type AssetSchema = z.infer<typeof assetSchema>

export const defaultValues: AssetSchema = {
  name: "",
  type: "",
  image: null,
  description: "",
  properties: [],
  sensorId: "",
}

export const getDefaultValues = ({
  name,
  type,
  description,
  properties,
  sensor,
}: AssetDetailsData): AssetSchema => {
  return {
    name,
    type: type.toString(),
    image: null,
    description,
    properties: Object.keys(properties).map((key) => {
      return {
        property: key,
        value: properties[key],
      }
    }),
    sensorId: sensor?.serialNumber ?? "",
  }
}
