import { MFooter } from "../../styles"
import { Button } from "../../../ui/Button"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

interface IModalFooter {
  onClose?: () => void
  onClick: () => void
  disableAction?: boolean
  buttonTitle: string
  type: "dialog" | "action"
}

export const ModalFooter = ({
  onClose,
  onClick,
  buttonTitle,
  type,
  disableAction = false,
}: IModalFooter) => {
  const { buttons } = useAppTranslate()

  return (
    <MFooter>
      {type === "dialog" && (
        <div className='modal-confirmation-buttons'>
          <Button onClick={onClose} variant='secondary'>
            {buttons.cancel}
          </Button>
          <Button
            onClick={onClick}
            disabled={disableAction}
            variant='destructive'
          >
            {buttonTitle}
          </Button>
        </div>
      )}

      {type === "action" && (
        <div className='modal-action-buttons'>
          <Button onClick={onClick} disabled={disableAction}>
            {buttonTitle}
          </Button>
        </div>
      )}
    </MFooter>
  )
}
