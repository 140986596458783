import { useNavigate } from "react-router-dom"
import {
  useRemoveAssetDetailsMutation,
  useUpdateAssetDetailsMutation,
  useRemoveAssetTreeMutation,
} from "../../../../../store/store"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useMemo } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useConfigureAsset = ({ id }: { id: string }) => {
  const { assets } = useAppTranslate()

  const navigate = useNavigate()

  const [updateAssetDetails, mutation] = useUpdateAssetDetailsMutation()
  const [removeAssetDetails, mutationRemove] = useRemoveAssetDetailsMutation()

  // TODO: Remove when integrated with the backend api
  const [removeAssetTree, mutationRemoveTree] = useRemoveAssetTreeMutation()

  const isDisable = useMemo(
    () =>
      mutation.isLoading ||
      mutationRemove.isLoading ||
      mutationRemoveTree.isLoading,
    [mutation.isLoading, mutationRemove.isLoading, mutationRemoveTree.isLoading]
  )

  const handleCancel = () => {
    navigate("/assets")
  }

  const handleSubmit = (data: AssetSchema) => {
    updateAssetDetails({ id, body: data })
      .unwrap()
      .then(() => {
        toast.success(assets.form.toastSucessConfigure)

        navigate("/assets")
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleDelete = () => {
    removeAssetDetails(id)
      .unwrap()
      .then(() => {
        removeAssetTree(id)
          .unwrap()
          .then(() => {
            toast.success(assets.form.toastSucessDelete)
            navigate("/assets")
          })
          .catch((e) => {
            console.log(e)
          })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return {
    handleCancel,
    handleDelete,
    handleSubmit,
    isDisable,
  }
}
