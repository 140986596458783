import { Text } from "../ui/Text"
import { CellRow } from "./components/CellRow"
import { useTable } from "./hooks/useTable"
import {
  Container,
  Root,
  Header,
  Body,
  HeaderRow,
  HeaderCell,
  EmptyBody,
  SortContainer,
} from "./table.styled"
import { Checkbox } from "../ui/Checkbox"
import { SnackBar } from "./components/SnackBar"
import { ReactNode } from "react"
import { ArrowsDownUp } from "phosphor-react"
import { Footer } from "./components/Footer"

export interface Column<T> {
  label: string
  render: (param: T) => string | number
  order?: {
    by: keyof T
  }
}

export interface TableProps<T> {
  columns: Column<T>[]
  data: T[]
  total: number
  itemsPerPage: number
  page: number
  onChangeItemsPerPage: (items: number) => void
  onChangePage: (page: number) => void
  checkbox?: boolean
  selectionType?: "checkbox" | "radio"
  expandedContent?: ReactNode
  emptyMessage: string
  onChangeData: (itemsPerPage: number, page: number) => void
  onCheck: (id: string | number) => void
}

export const Table = <
  T extends {
    id: string | number
  },
>({
  columns,
  data,
  total,
  itemsPerPage,
  page,
  onChangeItemsPerPage,
  onChangePage,
  checkbox = false,
  selectionType = "checkbox",
  emptyMessage,
  expandedContent,
  onChangeData,
  onCheck,
}: TableProps<T>) => {
  const hasExpandedContent = expandedContent !== undefined

  const {
    isEmpty,
    countChecked,
    sortBy,
    filtered,
    handleSortIcon,
    check,
    getCheckState,
    uncheckAll,
    checkAll,
    expand,
    getExpandState,
  } = useTable<T>({ data })

  return (
    <Container>
      <Root $checkbox={checkbox} $expand={hasExpandedContent}>
        <Header>
          <HeaderRow $rowsize={columns.length}>
            {checkbox && (
              <HeaderCell>
                {selectionType === "checkbox" && (
                  <Checkbox
                    checked={countChecked === data.length}
                    onChange={(e) => {
                      if (e.target.checked) checkAll()
                      else uncheckAll()
                    }}
                  />
                )}
              </HeaderCell>
            )}

            {columns.map((column, index) => {
              return (
                <HeaderCell key={index}>
                  <SortContainer $selected={column.order?.by === sortBy}>
                    <Text color='gray.800' fontSize='sm' fontWeight='medium'>
                      {column.label}
                    </Text>
                    {column.order && (
                      <ArrowsDownUp
                        size={20}
                        onClick={() => handleSortIcon(column.order?.by)}
                      />
                    )}
                  </SortContainer>
                </HeaderCell>
              )
            })}

            {hasExpandedContent && <HeaderCell />}
          </HeaderRow>
        </Header>

        {!isEmpty && (
          <Body>
            {filtered.map((row, index) => {
              return (
                <CellRow
                  key={index}
                  check={(id) => {
                    check(id)
                    onCheck(id)
                  }}
                  checked={checkbox ? getCheckState(row.id) : undefined}
                  expand={expand}
                  expanded={getExpandState(row.id)}
                  columns={columns}
                  row={row}
                  selectionType={selectionType}
                >
                  {expandedContent}
                </CellRow>
              )
            })}
          </Body>
        )}
      </Root>

      {isEmpty && (
        <EmptyBody>
          <Text color='gray.500' fontSize='sm' fontWeight='medium'>
            {emptyMessage}
          </Text>
        </EmptyBody>
      )}

      {countChecked !== 0 && selectionType !== "radio" && (
        <SnackBar count={countChecked} onClose={uncheckAll} />
      )}

      <Footer
        total={total}
        onChangeData={onChangeData}
        itemsPerPage={itemsPerPage}
        page={page}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
      />
    </Container>
  )
}
