import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { AssetTreeData } from "../../utils/entities/assets"

export interface AssetApi {
  id: string
  children: string[]
  name: string
  type: number
  isRoot?: boolean
}

const pause = (duration: number | undefined) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

const createAssetItem = (asset: AssetApi) => {
  return {
    index: asset.id,
    children: asset.children,
    data: {
      id: asset.id,
      name: asset.name,
      type: Number(asset.type),
    },
    isFolder: asset.children.length > 0,
    canMove: false,
    canRename: false,
  }
}

export const assetsApi = createApi({
  reducerPath: "assets",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3005",
    fetchFn: async (...args) => {
      //"This section ('await pause') of code is for testing purposes only
      //and will be removed before production deployment."
      await pause(1000)
      return fetch(...args)
    },
  }),
  endpoints: (build) => ({
    fetchAssets: build.query<AssetTreeData, void>({
      query: () => {
        return {
          url: "/assets",
          method: "GET",
        }
      },
      transformResponse: (response: AssetApi[]) => {
        const tree: AssetTreeData = {}

        const rootChildren: string[] = []

        response.forEach((asset) => {
          if (asset.isRoot) {
            rootChildren.push(asset.id)
          }

          tree[asset.id] = createAssetItem(asset)
        })

        tree["master"] = {
          index: "master",
          children: rootChildren,
          data: {
            id: "",
            name: "Assets",
            type: 1,
          },
          isFolder: rootChildren.length > 0,
          canMove: false,
          canRename: false,
        }

        return tree
      },
    }),
    fetchAssetTree: build.query<AssetApi, string>({
      query: (id) => {
        return {
          url: `/assets/${id}`,
          method: "GET",
        }
      },
    }),
    updateAssetTree: build.mutation<
      AssetApi,
      { id: string; children: string[] }
    >({
      query: ({ id, children }) => {
        return {
          url: `/assets/${id}`,
          method: "PATCH",
          body: { children },
        }
      },
    }),
    addAssetItem: build.mutation<
      void,
      { id: string; name: string; type: number }
    >({
      query: ({ id, name, type }) => {
        return {
          method: "POST",
          url: "/assets",
          body: {
            id,
            children: [],
            name,
            type,
          },
        }
      },
    }),
    removeAssetTree: build.mutation<void, string>({
      query: (id) => {
        return {
          method: "DELETE",
          url: `/assets/${id}`,
        }
      },
    }),
    fetchAssetTreeChildren: build.query<AssetApi[], string>({
      query: (id) => {
        return {
          url: `/assets/`,
          method: "GET",
        }
      },
      transformResponse: (response: AssetApi[], meta, id) => {
        const find = response.find((item) => item.id === id)

        if (!find) return []

        const children: AssetApi[] = []

        find.children.forEach((child) => {
          const childFind = response.find((item) => item.id === child)

          if (childFind) {
            children.push(childFind)
          }
        })

        return children
      },
    }),
  }),
})

export const {
  useFetchAssetsQuery,
  useFetchAssetTreeChildrenQuery,
  useAddAssetItemMutation,
  useFetchAssetTreeQuery,
  useUpdateAssetTreeMutation,
  useRemoveAssetTreeMutation,
} = assetsApi
