import {
  UncontrolledTreeEnvironment,
  Tree,
  TreeItemIndex,
  TreeItem,
  TreeItemRenderContext,
  TreeRef,
} from "react-complex-tree"
import { CustomDataProvider } from "./CustomDataProvider"
import { ReactNode, RefObject } from "react"
import { TreeItemRenderer } from "./components/TreeItemRenderer"
import { TreeAssetsContainer } from "./styles"
import { AssetData } from "../../../../../../utils/entities/assets"

interface TreeAssetsProps {
  dataProvider: CustomDataProvider
  handleOnClick: (itemIdx: TreeItemIndex) => void
  handleAsset: (id: string) => void
  tree: RefObject<TreeRef<string>>
}

interface RenderItemProps {
  item: TreeItem<AssetData>
  depth: number
  children: ReactNode | null
  title: ReactNode
  arrow: ReactNode
  context: TreeItemRenderContext<"expandedItems">
}

export const TreeAssets = ({
  dataProvider,
  handleOnClick,
  handleAsset,
  tree,
}: TreeAssetsProps) => {
  const renderItem = ({ item, ...props }: RenderItemProps) => {
    return (
      <TreeItemRenderer
        {...props}
        childrenAsset={item.children}
        itemIdx={item.index}
        type={item.data.type}
        label={item.data.name}
        handleOnClick={handleOnClick}
        handleAsset={handleAsset}
      />
    )
  }

  return (
    <TreeAssetsContainer>
      <UncontrolledTreeEnvironment
        canDragAndDrop
        canDropOnFolder
        canReorderItems
        dataProvider={dataProvider}
        getItemTitle={(item) => item.data.name}
        viewState={{ "tree-1": { expandedItems: ["root"] } }}
        renderDepthOffset={20}
        renderItemTitle={({ title }) => <span>{title}</span>}
        renderItem={renderItem}
        renderTreeContainer={({ children, containerProps }) => (
          <div {...containerProps}>{children}</div>
        )}
        renderItemsContainer={({ children, containerProps }) => (
          <ul {...containerProps}>{children}</ul>
        )}
      >
        <Tree
          treeId='tree-1'
          rootItem='master'
          treeLabel='Tree Example'
          ref={tree}
        />
      </UncontrolledTreeEnvironment>
    </TreeAssetsContainer>
  )
}
