import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const IconButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media ${media.medium} {
    display: none;
  }
`

export const ButtonsContainer = styled.div`
  gap: 10px;

  display: none;

  height: 100%;

  @media ${media.medium} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`
