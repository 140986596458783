import { memo } from "react"
import { UseFormHandleSubmit } from "react-hook-form"
import { AssetSchema } from "../../../utils/schema"
import { DashMenu } from "../../../../../Dashboard/view/styles"
import { Button } from "../../../../../../components/ui/Button"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { IconButton } from "../../../../../../components/ui/IconButton"
import { Check, ArrowLeft } from "phosphor-react"
import { IconButtonsContainer, ButtonsContainer } from "./styles"
import { useCreateAsset } from "../../controllers/useCreateAsset"

interface ActionsProps {
  handleFormSubmit: UseFormHandleSubmit<AssetSchema>
}

export const Actions = memo(({ handleFormSubmit }: ActionsProps) => {
  const { buttons } = useAppTranslate()

  const { handleCancel, handleCreate, isLoading } = useCreateAsset()

  return (
    <>
      <DashMenu>
        <IconButtonsContainer>
          <IconButton
            variant='secondary'
            icon={<ArrowLeft size={24} />}
            onClick={handleCancel}
          />

          <IconButton
            icon={<Check size={24} />}
            onClick={handleFormSubmit(handleCreate)}
            disabled={isLoading}
          />
        </IconButtonsContainer>

        <ButtonsContainer>
          <Button variant='secondary' onClick={handleCancel}>
            {buttons.cancel}
          </Button>
          <Button onClick={handleFormSubmit(handleCreate)} disabled={isLoading}>
            {isLoading ? buttons.loading : buttons.save}
          </Button>
        </ButtonsContainer>
      </DashMenu>
    </>
  )
})

Actions.displayName = "Actions"
