import styled from "styled-components"

interface IconWrapperProps {
  $isClicked: boolean
}

export const Container = styled.div`
  cursor: pointer;
`

export const IconWrapper = styled.div<IconWrapperProps>`
  svg {
    color: ${({ $isClicked }) =>
      $isClicked
        ? ({ theme }) => theme.palette.secondary.main
        : ({ theme }) => theme.palette.gray[700]};
  }
`
