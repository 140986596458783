import { useMemo, useState } from "react"
import { useUpdateSensorMutation } from "../../../../../store/api/assets/assets-details"
import { toast } from "react-toastify"

export const useConfigureSensor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectSensorId, setSelectSensorId] = useState<string>()

  const [updateSensor, mutation] = useUpdateSensorMutation()

  const isActionDisable = useMemo(
    () => mutation.isLoading || !selectSensorId,
    [mutation.isLoading, selectSensorId]
  )

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setSelectSensorId(undefined)
    setIsModalOpen(false)
  }

  const handleSelectSensor = (sensorId: string) => {
    setSelectSensorId(sensorId)
  }

  const handleConfigureSensor = (assetId: string | undefined) => {
    if (!assetId || !selectSensorId) return

    updateSensor({ assetId, sensorId: selectSensorId })
      .unwrap()
      .then(() => handleCloseModal())
      .catch(() => {
        toast.error("Erro ao adicionar sensor")
      })
  }

  return {
    handleOpenModal,
    handleCloseModal,
    handleConfigureSensor,
    handleSelectSensor,
    isModalOpen,
    isActionDisable,
  }
}
