import { ModalHedro } from "../../../../../components/ModalHedro"
import { Title } from "../../../../../components/Title"
import { Button } from "../../../../../components/ui/Button"
import { AssetSensorsTable } from "../AssetSensorsTable"
import { Container, TitleArea } from "./styles"
import { useConfigureSensor } from "../../List/controllers/useConfigureSensor"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface AssetWithoutSensorProps {
  assetId: string
}

export const AssetWithoutSensor = ({ assetId }: AssetWithoutSensorProps) => {
  const { assets } = useAppTranslate()

  const {
    isModalOpen,
    isActionDisable,
    handleCloseModal,
    handleSelectSensor,
    handleConfigureSensor,
    handleOpenModal,
  } = useConfigureSensor()

  return (
    <>
      {isModalOpen && (
        <ModalHedro
          onClose={handleCloseModal}
          onClick={() => handleConfigureSensor(assetId)}
          actionBar={assets.sensors.addSensor}
          title={assets.sensors.addModalTitle}
          disableAction={isActionDisable}
        >
          <AssetSensorsTable
            onCheck={(id) => handleSelectSensor(id.toString())}
          />
        </ModalHedro>
      )}

      <TitleArea>
        <Title label='Sensor' size='sm' weight='medium' />
      </TitleArea>
      <Container>
        <Text fontSize='sm' color='gray.500' fontWeight='regular'>
          {assets.sensors.emptyLabel}
        </Text>
        <Button variant='link' onClick={handleOpenModal}>
          {assets.sensors.addSensor}
        </Button>
      </Container>
    </>
  )
}
