import { UseFormReturn, useFieldArray, useWatch } from "react-hook-form"
import { Input } from "../../../../../components/ui/Input"
import { AssetSchema, SCHEMA_ERRORS } from "../../utils/schema"
import {
  Wrapper,
  AssetInfo,
  BasicInfo,
  AssetDescription,
  Content,
  PropertiesMessage,
  PropertiesFields,
} from "./styles"
import { useEffect, useRef } from "react"
import { AssetType, getProperties } from "../../../../../utils/entities/assets"
import { Divider } from "../../../../../components/ui/Divider"
import { TextArea } from "../../../../../components/ui/TextArea"
import { AssetSensorsTable } from "../../components/AssetSensorsTable"
import { Title } from "../../../../../components/Title"
import { ImageContainer } from "../ImageContainer"
import { AssetFormSkeleton } from "./AssetForm.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { InputSelect } from "../Select"

const getTypeLabel = (type: number) => {
  switch (type) {
    case AssetType.SITE:
      return "Local"
    case AssetType.MOTOR:
      return "Motor"
    case AssetType.ADL:
      return "ADL"
    default:
      return ""
  }
}

interface AssetFormProps {
  form: UseFormReturn<AssetSchema>
  isConfigure?: boolean
}

const AssetForm = ({ form, isConfigure = false }: AssetFormProps) => {
  const { assets } = useAppTranslate()

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.INVALID_FILE_SIZE:
        return assets.form.errors.invalidFileSize
      case SCHEMA_ERRORS.INVALID_IMAGE_TYPE:
        return assets.form.errors.invalidFileType
      case SCHEMA_ERRORS.REQUIRED:
        return assets.form.errors.required
      default:
        return assets.form.errors.default
    }
  }

  const {
    control,
    setFocus,
    register,
    clearErrors,
    formState: { errors },
  } = form

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  })

  const type = useWatch({
    control,
    name: "type",
  })

  const isFirstConfigureRender = useRef(isConfigure)

  useEffect(() => {
    if (!type) return

    if (isFirstConfigureRender.current) {
      isFirstConfigureRender.current = false
      return
    }

    const properties = getProperties(Number(type))

    remove()

    properties.forEach((property) => {
      append({ property, value: "" })
    })
  }, [remove, append, type, fields.length, setFocus])

  useEffect(() => {
    if (fields.length <= 0) return

    if (isConfigure) setFocus("name")
    if (!isConfigure) setFocus("type")
  }, [fields, isConfigure, setFocus])

  const options: number[] = Object.keys(AssetType)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key))

  const handleSelectSensor = (id: string | number) => {
    form.setValue("sensorId", id.toString())
  }

  return (
    <Wrapper>
      <AssetInfo>
        <ImageContainer
          editable
          {...register("image")}
          error={getErrorMessage(errors.image?.message as string)}
          onReset={() => clearErrors("image")}
        />
        <BasicInfo>
          <Input
            label={assets.form.name}
            placeholder={assets.form.namePlaceholder}
            error={getErrorMessage(errors.name?.message)}
            {...register("name")}
          />
          <InputSelect
            label={assets.details.type}
            options={options}
            error={getErrorMessage(errors.type?.message)}
            renderLabel={(option) => getTypeLabel(Number(option))}
            {...register("type")}
          />
        </BasicInfo>
      </AssetInfo>

      <Divider />

      <AssetDescription>
        <TextArea
          label={assets.details.description}
          rows={10}
          placeholder={assets.form.descriptionPlaceholder}
          error={getErrorMessage(errors.description?.message)}
          {...register("description")}
        />
      </AssetDescription>

      <Divider />

      <Content>
        <Title label={assets.details.properties} size='sm' weight='medium' />

        {fields.length === 0 && (
          <PropertiesMessage>
            <Title
              label={assets.form.propertiesPlaceholder}
              size='sm'
              weight='regular'
            />
          </PropertiesMessage>
        )}

        {fields.length > 0 && (
          <PropertiesFields>
            {fields.map((field, index) => {
              return (
                <Input
                  key={field.id}
                  label={field.property}
                  placeholder={`${field.property}`}
                  {...register(`properties.${index}.value`)}
                />
              )
            })}
          </PropertiesFields>
        )}
      </Content>

      <Divider />

      <Content>
        <Title label='Sensor' size='sm' weight='medium' />
        <AssetSensorsTable onCheck={handleSelectSensor} />
      </Content>
    </Wrapper>
  )
}

export { AssetForm, AssetFormSkeleton }
