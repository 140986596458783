import { InputContainer, TreeAssetsHeader } from "./styles"
import { Title } from "../../../../../../../../components/Title"
import { Divider } from "../../../../../../../../components/ui/Divider"
import { MagnifyingGlass } from "phosphor-react"
import { Input } from "../../../../../../../../components/ui/Input"
import { RefObject, useState } from "react"
import { TreeItemIndex, TreeRef } from "react-complex-tree"
import { TreeContentController } from "./treeContentController"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"
import { CustomDataProvider } from "../../../TreeAssets/CustomDataProvider"
import { TreeAssets } from "../../../TreeAssets"

interface TreeContentProps {
  dataProvider: CustomDataProvider
  handleOnClick: (itemIdx: TreeItemIndex) => void
  handleAsset: (id: string) => void
  tree: RefObject<TreeRef<string>>
}

export const TreeContent = ({
  dataProvider,
  handleAsset,
  tree,
  handleOnClick,
}: TreeContentProps) => {
  const [search, setSearch] = useState("")

  const { find } = TreeContentController(dataProvider, search, tree)
  const { assets } = useAppTranslate()

  return (
    <>
      <TreeAssetsHeader>
        <Title label={assets.tree.title} size='md' />
      </TreeAssetsHeader>

      <Divider />
      <InputContainer>
        <Input
          placeholder={assets.tree.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          icon={<MagnifyingGlass size={20} weight='bold' />}
          onClickIcon={find}
        />
      </InputContainer>

      <TreeAssets
        dataProvider={dataProvider}
        handleOnClick={handleOnClick}
        handleAsset={handleAsset}
        tree={tree}
      />
    </>
  )
}
