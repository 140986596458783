import ReactDOM from "react-dom"
import { useEffect } from "react"
import {
  ModalCard,
  ModalOverlay,
  ModalContainer,
  ModalContent,
  ModalSize,
} from "./styles"

import { ModalHeader } from "./components/ModalHeader"
import { ModalFooter } from "./components/ModalFooter"

export interface IModalHedro {
  onClose: () => void
  onClick: () => void
  disableAction?: boolean
  children: React.ReactNode
  actionBar: string
  size?: ModalSize
  type?: "dialog" | "action"
  title: string
  subtitle?: string
}

export const ModalHedro = ({
  onClose,
  onClick,
  disableAction = false,
  children,
  actionBar,
  size = "md",
  type = "dialog",
  title,
  subtitle,
}: IModalHedro) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden")

    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [])

  return ReactDOM.createPortal(
    <div>
      <ModalOverlay onClick={onClose} />
      <ModalCard $size={size}>
        <ModalContainer>
          <ModalHeader onClose={onClose} title={title} subtitle={subtitle} />
          <ModalContent>{children}</ModalContent>
          <ModalFooter
            buttonTitle={actionBar}
            type={type}
            onClose={onClose}
            onClick={onClick}
            disableAction={disableAction}
          />
        </ModalContainer>
      </ModalCard>
    </div>,
    document.querySelector(".modal-container") as HTMLElement
  )
}
