import { memo, useState } from "react"
import { UseFormHandleSubmit } from "react-hook-form"
import { AssetSchema } from "../../../utils/schema"
import { useConfigureAsset } from "../../controllers/useConfigureAsset"
import { DashMenu } from "../../../../../Dashboard/view/styles"
import { Button } from "../../../../../../components/ui/Button"
import { ModalDeleteAsset } from "../ModalDeleteAsset"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { IconButton } from "../../../../../../components/ui/IconButton"
import { Check, Trash, ArrowLeft } from "phosphor-react"
import { IconButtonsContainer, ButtonsContainer } from "./styles"

interface ActionsProps {
  id: string
  isRoot: boolean
  handleFormSubmit: UseFormHandleSubmit<AssetSchema>
}

export const Actions = memo(
  ({ id, isRoot, handleFormSubmit }: ActionsProps) => {
    const { buttons, assets } = useAppTranslate()

    const { handleCancel, handleDelete, handleSubmit, isDisable } =
      useConfigureAsset({ id })

    const [open, setOpen] = useState(false)

    return (
      <>
        {open && (
          <ModalDeleteAsset
            id={id}
            handleOnClose={() => setOpen(false)}
            handleDelete={handleDelete}
          />
        )}

        <DashMenu>
          <IconButtonsContainer>
            <IconButton
              variant='secondary'
              icon={<ArrowLeft size={24} />}
              onClick={handleCancel}
            />

            {!isRoot && (
              <IconButton
                variant='secondary'
                icon={<Trash size={24} />}
                onClick={() => setOpen(true)}
                disabled={isDisable}
              />
            )}

            <IconButton
              icon={<Check size={24} />}
              onClick={handleFormSubmit(handleSubmit)}
              disabled={isDisable}
            />
          </IconButtonsContainer>

          <ButtonsContainer>
            <Button variant='secondary' onClick={handleCancel}>
              {buttons.cancel}
            </Button>

            {!isRoot && (
              <Button
                variant='secondary'
                onClick={() => setOpen(true)}
                disabled={isDisable}
              >
                {assets.form.deleteAsset}
              </Button>
            )}

            <Button
              variant='primary'
              onClick={handleFormSubmit(handleSubmit)}
              disabled={isDisable}
            >
              {assets.form.saveChanges}
            </Button>
          </ButtonsContainer>
        </DashMenu>
      </>
    )
  }
)

Actions.displayName = "Actions"
