import { ModalHedro } from "../../../../../../components/ModalHedro"
import {
  AssetApi,
  useFetchAssetTreeChildrenQuery,
} from "../../../../../../store/api/AssetsApi"
import { AssetIcon } from "../../../List/components/TreeAssets/components/AssetIcon"
import { Text } from "../../../../../../components/ui/Text"
import { Asset, Body } from "./styles"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"

export const ModalDeleteAsset = ({
  id,
  handleOnClose,
  handleDelete,
}: {
  id: string
  handleOnClose: () => void
  handleDelete: () => void
}) => {
  const { assets } = useAppTranslate()

  const { data, isLoading, isError } = useFetchAssetTreeChildrenQuery(id)

  return (
    <ModalHedro
      onClose={handleOnClose}
      onClick={handleDelete}
      actionBar={assets.form.deleteAsset}
      title={assets.form.deleteModalTitle}
      disableAction={isLoading || isError}
    >
      <Content data={data} isLoading={isLoading} isError={isError} />
    </ModalHedro>
  )
}

const Content = ({
  isError,
  isLoading,
  data,
}: {
  isLoading: boolean
  isError: boolean
  data: AssetApi[] | undefined
}) => {
  const { assets } = useAppTranslate()

  if (isLoading) return <Text>{assets.details.loading}</Text>

  if (isError || !data) return <Text>{assets.details.error}</Text>

  return (
    <Body>
      <Text color='gray.600'>
        {data.length === 0
          ? assets.form.deleteMessage
          : assets.form.deleteMessageWithChildren}
      </Text>

      {data.map((item) => (
        <Asset key={item.id}>
          <AssetIcon type={item.type} />
          <Text color='gray.800'>{item.name}</Text>
        </Asset>
      ))}
    </Body>
  )
}
