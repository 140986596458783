import { useState } from "react"
import { List } from "@phosphor-icons/react"
import { NewMenu } from "../NewMenu"
import { OverlayNewMenu } from "../NewMenu/styles/Navbar.styled"
import { IconButton } from "../ui/IconButton"
import { Header } from "./style"
import { PageTitle } from "./components/PageTitle"
import { TitleContainer } from "./components/PageTitle/styled"

type NewHeaderProps = {
  page: {
    title: string
    subTitle?: string
  }
}

export const NewHeader = ({ page }: NewHeaderProps) => {
  const [openMenu, setOpenMenu] = useState(false)

  const toggleNewMenu = () => {
    setOpenMenu(!openMenu)
  }
  return (
    <Header>
      <IconButton
        variant='secondary'
        icon={<List size={24} />}
        onClick={toggleNewMenu}
      />
      <TitleContainer>
        <NewMenu open={openMenu} />
        <OverlayNewMenu $open={openMenu} onClick={toggleNewMenu} />
        <PageTitle {...page} />
      </TitleContainer>
    </Header>
  )
}
