import { Navigate, Route, Routes } from "react-router-dom"
import { useAuth } from "react-oidc-context"
import { Login } from "../pages/Login"
import { Dashboard } from "../pages/Dashboard"
import { ErrorPage } from "../pages/ErrorPage"
import { LoadingPage } from "../pages/LoadingPage"
import { SensorsProv } from "../pages/SensorsProv"
import { SensorsConfig } from "../pages/SensorsConfig"
import { RegisterCompany } from "../pages/RegisterCompany"
import { RegisterSale } from "../pages/RegisterSale"
import { Reports } from "../pages/Reports"
import { ProtectedRoute } from "../utils/authentication/ProtectedRoute"
import { PermissionsProteu } from "../store/features/user/user.interfaces"
import { AlarmsLogs } from "../pages/AlarmsLogs"
import { AlarmsConfig } from "../pages/AlarmsConfig"
import { RegisterUserView } from "../pages/RegisterUser"
import { HistoricalAnalysis } from "../pages/HistoricalAnalysis"
import { Assets } from "../pages/v3/Assets/List"
import { CreateAsset } from "../pages/v3/Assets/Create"
import { GatewayPage } from "../pages/v3/Gateways"
import { ConfigureAsset } from "../pages/v3/Assets/Configure"

export enum Pages {
  DASHBOARD = "dashboard",
  SENSORS_PROV = "sensorsprov",
  SENSOR_CONFIG = "sensorsconfig",
  GATEWAYS = "gateways",
  REGISTER_COMPANY = "registercompany",
  REGISTER_SALES = "registersales",
  REGISTER_USER = "registeruser",
  REPORTS = "reports",
  HISTORICAL_ANALYSIS = "historicalanalysis",
  ALARM_LOGS = "alarmslogs",
  ALARM_CONFIG = "alarmsconfig",
  ASSETS = "assets",
  ASSETS_CREATE = "assets/create/:parentId",
  ASSETS_CONFIGURE = "assets/configure/:id",
}

export default function Router() {
  const { isAuthenticated, isLoading, error } = useAuth()

  if (isLoading) return <LoadingPage />

  if (error) {
    return <ErrorPage />
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to={"login"} />} />
      <Route path='login' element={<Login />} />

      <Route
        path={`/${Pages.DASHBOARD}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.DashboardEnable}
          >
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.SENSORS_PROV}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.SensorsProvEnable}
          >
            <SensorsProv />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.SENSOR_CONFIG}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.SensorsConfigEnable}
          >
            <SensorsConfig />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.ASSETS}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.AssetsEnable}
          >
            <Assets />
          </ProtectedRoute>
        }
      />

      <Route
        path={Pages.ASSETS_CREATE}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.AssetsEnable}
          >
            <CreateAsset />
          </ProtectedRoute>
        }
      />

      <Route
        path={Pages.ASSETS_CREATE}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.AssetsEnable}
          >
            <CreateAsset />
          </ProtectedRoute>
        }
      />

      <Route
        path={Pages.ASSETS_CONFIGURE}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.AssetsEnable}
          >
            <ConfigureAsset />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.GATEWAYS}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.GatewaysEnable}
          >
            <GatewayPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.REPORTS}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.ReportsEnable}
          >
            <Reports />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/${Pages.HISTORICAL_ANALYSIS}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.HistoricalAnalysisEnable}
          >
            <HistoricalAnalysis />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/${Pages.ALARM_LOGS}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.AlarmsLogsEnable}
          >
            <AlarmsLogs />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.ALARM_CONFIG}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.AlarmsLogsEnable}
          >
            <AlarmsConfig />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.REGISTER_COMPANY}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.ManagerCompaniesEnable}
          >
            <RegisterCompany />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.REGISTER_USER}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.ManagerUsersEnable}
          >
            <RegisterUserView />
          </ProtectedRoute>
        }
      />

      <Route
        path={`/${Pages.REGISTER_SALES}`}
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            permissions={PermissionsProteu.ManagerSalesEnable}
          >
            <RegisterSale />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}
