import { RefObject, useCallback } from "react"
import { TreeItemIndex, TreeRef } from "react-complex-tree"
import { CustomDataProvider } from "../../../TreeAssets/CustomDataProvider"

export const TreeContentController = (
  dataProvider: CustomDataProvider,
  search: string,
  tree: RefObject<TreeRef<string>>
) => {
  const findItemPath = useCallback(
    async (
      search: string,
      searchRoot: TreeItemIndex = "1"
    ): Promise<string[] | null> => {
      const item = await dataProvider.getTreeItem(searchRoot)
      if (item.data.name.toLowerCase().includes(search.toLowerCase())) {
        return [item.index.toString()]
      }
      const searchedItems = await Promise.all(
        item.children?.map((child) => findItemPath(search, child)) ?? []
      )
      const result = searchedItems.find((item) => item !== null)
      if (!result) {
        return null
      }
      return [item.index.toString(), ...result]
    },
    [dataProvider]
  )

  const find = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault()
      if (search) {
        findItemPath(search).then((path) => {
          if (path) {
            tree.current?.expandSubsequently(path).then(() => {
              tree.current?.selectItems([path[path.length - 1]])
              tree.current?.focusItem(path[path.length - 1])
            })
          }
        })
      }
    },
    [findItemPath, search, tree]
  )

  return {
    find,
  }
}
